import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Image,
  Container,
  Col,
  Row,
  Modal,
  Button,
  Carousel,
  Spinner,
} from 'react-bootstrap';
import { useAuth0 } from '../../react-auth0-spa';
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../store/actions/projects';
import Search from './Search';
import './Projects.scss';

const Project = React.memo(props => {
  const [fetchingState, setFetchingState] = useState({
    fetching: false,
  });
  const [show, setShow] = useState(null);
  const dispatch = useDispatch();
  const projects = useSelector(state => state.projects.filteredProjects);

  const { isAuthenticated, getTokenSilently } = useAuth0();

  const onClickHandler = id => {
    getTokenSilently().then(token =>
      dispatch(projectActions.deleteProject(token, id))
    );
  };
  const fetchProducts = useCallback(() => {
    setFetchingState({ fetching: true });
    dispatch(projectActions.getProjects()).then(() =>
      setFetchingState({ fetching: false })
    );
  }, [dispatch]);

  useEffect(() => {
    document.title= 'Portfolio'
    fetchProducts();
  }, [fetchProducts]);

  const handleClose = () => setShow(null);
  const handleShow = id => setShow(id);

  let captionPulse = isAuthenticated ? 'calc(100% - 80px)' : '100%';

  return (
    <main >
      {fetchingState.fetching && (
        <Spinner className='app-spinner' animation='border' variant='primary' />
      )}
      {/* Get filtered projects from selection on Search Component and filter them to show only the selected ones. */}
      <Search />

      <Container as="section" fluid='xs'>
        <Row xs='auto' noGutters>
          {projects &&
            projects.map(project => (
              <Col
                key={project._id}
                className='project'
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
              >
                <div id='open-modal' onClick={() => handleShow(project._id)}>
                  <div
                    className='caption pulse'
                    style={{ height: captionPulse }}
                  >
                    <div className='caption-content'>
                      <i className='fa fa-search-plus fa-3x'></i>
                    </div>
                  </div>
                </div>
                <Image
                  src={project.image}
                  className='project'
                  alt={project.imgdescription}
                  fluid
                />

                {isAuthenticated && (
                  <div>
                    <Link
                      className='project-edit'
                      to={{
                        pathname: 'project-edit',
                        search: `?project=${project._id}`,
                        state: { project: project },
                      }}
                    >
                      Edit
                    </Link>
                    <Button
                      className='project-delete'
                      variant='danger'
                      onClick={() => onClickHandler(project._id)}
                    >
                      Delete
                    </Button>
                  </div>
                )}
                <Modal show={show === project._id} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>{project.title}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Carousel>
                      <Carousel.Item>
                        <Image
                          src={project.image}
                          className='project'
                          alt={project.imgdescription}
                          fluid
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <Image
                          src={project.image}
                          className='project'
                          alt={project.imgdescription}
                          fluid
                        />
                      </Carousel.Item>
                    </Carousel>
                    {project.description}
                    <ul className='list-inline item-details'>
                      <li>
                        Link:{' '}
                        <strong>
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={project.url}
                          >
                            Project link
                          </a>
                        </strong>
                      </li>
                      <li>
                        Service:
                        <strong> {project.service}</strong>
                      </li>
                    </ul>
                  </Modal.Body>
                  <Button variant='secondary' onClick={handleClose}>
                    Close
                  </Button>
                </Modal>
              </Col>
            ))}
        </Row>
      </Container>
    </main>
  );
});

export default Project;
