import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button, Jumbotron } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '../../react-auth0-spa';
import * as projectActions from '../../store/actions/projects';
import serializeForm from 'form-serialize';

const EditProjectForm = props => {
  const { getTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  // location gets data from the router object. the date is loaded on the link with the to property. see projects links to edit.
  const project = props.location.state.project;

  const submitHandler = e => {
    e.preventDefault();
    const values = serializeForm(e.target, { hash: true });
    values._id = project._id;
    getTokenSilently().then(token =>
      dispatch(projectActions.updateProject(token, values))
    );
    props.onEditProject();
  };

  const convertDate = () => {
    let date = new Date(Date.parse(project.completion));
    return date.toISOString().slice(0, 10);
  };

  return (
    <Jumbotron>
      <Form onSubmit={submitHandler}>
        <Form.Group>
          <Form.Label>Project title</Form.Label>
          <Form.Control
            id='title'
            name='title'
            type='text'
            defaultValue={project.title}
            onChange={() => {}}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Project description</Form.Label>
          <Form.Control
            name='description'
            type='text'
            defaultValue={project.description}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Service</Form.Label>
          <Form.Control
            name='service'
            type='text'
            defaultValue={project.service}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>URL</Form.Label>
          <Form.Control name='url' type='text' defaultValue={project.url} />
        </Form.Group>
        <Form.Group>
          <Form.Label>image URL</Form.Label>
          <Form.Control name='image' type='text' defaultValue={project.image} />
        </Form.Group>
        <Form.Group controlId='formGroupImageDescription'>
          <Form.Label>Image Description</Form.Label>
          <Form.Control
            name='imgdescription'
            type='text'
            defaultValue={project.imgdescription}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Completion date</Form.Label>
          <Form.Control
            name='completion'
            type='date'
            pattern='/(^(((0[1-9]|1[0-9]|2[0-8])[-](0[1-9]|1[012]))|((29|30|31)[-](0[13578]|1[02]))|((29|30)[-](0[4,6,9]|11)))[-](19|[2-9][0-9])\d\d$)|(^29[-]02[-](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/'
            title='please pick a date'
            defaultValue={convertDate()}
          />
        </Form.Group>
        <Button type='submit'>Update</Button>
      </Form>
    </Jumbotron>
  );
};

export default withRouter(EditProjectForm);
