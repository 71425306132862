import React from 'react';
import { Form, Button, Jumbotron } from 'react-bootstrap';
import { useAuth0 } from '../../react-auth0-spa';
import * as skillsActions from '../../store/actions/skills';
import { useDispatch } from 'react-redux';
import serializeForm from 'form-serialize';

const SkillsForm = props => {
  const { getTokenSilently } = useAuth0();
  const getToken = async () => await getTokenSilently();
  const token = getToken();
  const dispatch = useDispatch();

  const submitHandler = async e => {
    e.preventDefault();
    const values = serializeForm(e.target, { hash: true });
    dispatch(skillsActions.addSkill(await token, values));

    props.onCreateSkill();
  };

  return (
    <Jumbotron>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='formGroupName'>
          <Form.Label>Skill Name</Form.Label>
          <Form.Control name='name' type='text' placeholder='Skill name' />
        </Form.Group>
        <Form.Group controlId='formGroupImage'>
          <Form.Label>Category</Form.Label>
          <Form.Control name='category' as='select'>
            <option>Development</option>
            <option>Productivity</option>
            <option>Design</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId='formGroupknowhow'>
          <Form.Label>KnowHow</Form.Label>
          <Form.Control
            name='knowhow'
            type='number'
            placeholder='knowhow percentage'
          />
        </Form.Group>
        <Button type='submit'>Add</Button>
      </Form>
    </Jumbotron>
  );
};

export default SkillsForm;
