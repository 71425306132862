import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";
import { useDispatch, useSelector } from "react-redux";
import * as skillsActions from "../../store/actions/skills";
import { Spinner } from "react-bootstrap";
import "./Skills.scss";

const Skills = React.memo((props) => {
  const skills = useSelector((state) => state.skills.skills);
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const [fetchingState, setFetchingState] = useState({
    fetching: false,
  });

  const fetchSkills = useCallback(() => {
    setFetchingState({ fetching: true });
    dispatch(skillsActions.getSkills()).then(() =>
      setFetchingState({ fetching: false })
    );
  }, [dispatch]);

  useEffect(() => {
    document.title = 'Skills'
    fetchSkills();
  }, [fetchSkills]);

  return (
    <main>
      {fetchingState.fetching && (
        <Spinner className="app-spinner" animation="border" variant="primary" />
      )}
      <section className="skills">
        <ul className="skills-ul lines ">
          <li className="line l--0">
            <span className="line__label title">Skill level:</span>
          </li>
          <li className="line l--25">
            <span className="line__label">Basics</span>
          </li>
          <li className="line l--50">
            <span className="line__label">Intermediate</span>
          </li>
          <li className="line l--75">
            <span className="line__label">Advanced</span>
          </li>
          <li className="line l--100">
            <span className="line__label">Expert</span>
          </li>
        </ul>

        <div className="charts">
          <div className="chart chart--dev">
            <span className="chart__title">Development</span>
            <ul className="skills-ul chart--horiz">
              {skills
                .filter((skill) => skill.category === "Development")
                .map((skill) => {
                  let skillKnowhow = skill.knowhow + "%";
                  return (
                    <li
                      key={skill._id}
                      className="chart__bar"
                      style={{ width: skillKnowhow }}
                    >
                      {isAuthenticated ? (
                        <Link
                          className="skill-edit"
                          to={{
                            pathname: "skill-edit",
                            search: `?skill=${skill._id}`,
                            state: { skill: skill },
                          }}
                        >
                          <span className="chart__label" title="Edit skill">
                            {skill.name}
                          </span>
                        </Link>
                      ) : (
                        <span className="chart__label">{skill.name}</span>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="chart chart--prod">
            <span className="chart__title">Productivity</span>
            <ul className="skills-ul chart--horiz">
              {skills
                .filter((skill) => skill.category === "Productivity")
                .map((skill) => {
                  let skillKnowhow = skill.knowhow + "%";
                  return (
                    <li
                      key={skill._id}
                      className="chart__bar"
                      style={{ width: skillKnowhow }}
                    >
                      {isAuthenticated ? (
                        <Link
                          className="skill-edit"
                          to={{
                            pathname: "skill-edit",
                            search: `?skill=${skill._id}`,
                            state: { skill: skill },
                          }}
                        >
                          <span className="chart__label" title="Edit skill">
                            {skill.name}
                          </span>
                        </Link>
                      ) : (
                        <span className="chart__label">{skill.name}</span>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="chart chart--design">
            <span className="chart__title">Design</span>
            <ul className="skills-ul chart--horiz">
              {skills
                .filter((skill) => skill.category === "Design")
                .map((skill) => {
                  let skillKnowhow = skill.knowhow + "%";
                  return (
                    <li
                      key={skill._id}
                      className="chart__bar"
                      style={{ width: skillKnowhow }}
                    >
                      {isAuthenticated ? (
                        <Link
                          className="skill-edit"
                          to={{
                            pathname: "skill-edit",
                            search: `?skill=${skill._id}`,
                            state: { skill: skill },
                          }}
                        >
                          <span className="chart__label" title="Edit skill">
                            {skill.name}
                          </span>
                        </Link>
                      ) : (
                        <span className="chart__label">{skill.name}</span>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
});

export default Skills;
