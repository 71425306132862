import React,{useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './MyProfile.css';

const MyProfile = () => {

    useEffect(()=>{
        document.title= 'About me'
    })

    return (
        <Container className="myprofile-container" as="main">
            <Row>
                <Col xs="10" md="12" lg="10">
                    <h1>
                        Hi I’m Jordi,
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col xs="10" md="12" lg="10">
                    <h2>
                        I'm a Full-Stack designer based in Chelmsford.
                        </h2>
                </Col>
            </Row>
            <Row as="section">
                <Col xs="12" sm="10" md="4" lg="3" as="article" className="profile-description">
                    <p>
                        I have always been passionate about programming. I started applications on C# but what I enjoyed the most was the XAML part of it.
                        That is how I decided to go into front-end web applications development.
                        I love building attractive and responsive applications and progressively keep improving them to make them outstanding.
                    </p>
                    <p>
                        I always strive to get the full picture. I wasn't happy only working with APIs. I wanted to know what was behind. 
                        That is how I started to learn Back-end.
                        Now I'm a Full-Stack developer currently working with React on the Front-end and Node on the back-end.
                        I enjoy challenging myself, learning new programming paradigms and languages. 
                   </p>
                </Col >
                <Col xs="12" sm="10" md="5" as="article" className="profile-main">

                    <p>
                        I studied electronics, microprocessor (PLD) and PLC programming. I worked in controls for years. 
                        It was the programming part though, communicating on the network, make sure the packets are OK and logic behind it what I really enjoyed.
                        That taught me logic and to think out of the box to find solutions. Many times creating my own personalised solution to the problem.
                        I always kept my programs tidy with relevant comments to quickly maintain it afterwards.
                        I started with Turbo Pascal and after a brief affair with Visual Basic y moved in to C#.
                        C# was interesting but the Web kept calling. I eventually listen and I got hooked into Javascript, AngularJS and now ReactJS.
                        I always enjoyed working on a team and learning new skills and new ways to work.
                        I would describe myself as a continued learner that it not happy with only a basic knowledge but strives to get a complete understanding.
                    </p>
                    <p>
                        I'm not only a programer though. I love experiencing new cultures through travelling and kepping healthy and fit.
                        P.S. I love Mexico
                    </p>
                </Col>
               
            </Row>
            <Row as="section" className="portfolio-link">
                 <Col xs="12">
                <a target="_blank" rel="noopener noreferrer" href="https://interactive-resume-2753d.web.app/">
                 Previous version of my portfolio build with AngularJS
                </a>
                </Col>
            </Row>


        </Container>
    )
}

export default MyProfile; 