import { GET_SKILLS, ADD_SKILL, UPDATE_SKILL, DELETE_SKILL } from "../actions/skills";

const initalState = {
  skills: [],
};

const skillsReducer = (state = initalState, action) => {
  let updatedSkills;
  switch (action.type) {
    case GET_SKILLS:
      updatedSkills = action.skills.sort((a, b) => {
        return 0.5 - Math.random();
      });
      return {
        ...state,
        skills: updatedSkills,
      };
    case ADD_SKILL:
      updatedSkills = state.skills.concat(action.skills.skills[0]);
      return {
        ...state,
        skills: updatedSkills,
      };
    case UPDATE_SKILL:
      updatedSkills = state.skills
        .filter((s) => s._id !== action.skill.skills[0]._id)
        .concat(action.skill.skills[0]);
      return {
        ...state,
        skills: updatedSkills,
      };
      case DELETE_SKILL:
        console.log(action);
        updatedSkills= state.skills.filter(skill=>skill._id !== action.id.skills);
        return {
          ...state,
          skills: updatedSkills,
        };
    default:
      return state;
  }
};

export default skillsReducer;
