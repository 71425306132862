import React from 'react';
import { Form, Button, Jumbotron } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as projectActions from '../../store/actions/projects';
import { useAuth0 } from '../../react-auth0-spa';
import serializeForm from 'form-serialize';

const ProjectForm = props => {
  const { getTokenSilently } = useAuth0();
  const getToken = async () => await getTokenSilently();
  const token = getToken();
  const dispatch = useDispatch();

  const submitHandler = async e => {
    e.preventDefault();
    const values = serializeForm(e.target, { hash: true });
    dispatch(projectActions.addProject(await token, values));
    props.onCreateProject();
  };

  return (
    <Jumbotron>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='formGroupName'>
          <Form.Label>Project title</Form.Label>
          <Form.Control name='title' type='text' placeholder='Project title' />
        </Form.Group>
        <Form.Group controlId='formGroupDescription'>
          <Form.Label>Project description</Form.Label>
          <Form.Control
            name='description'
            type='text'
            placeholder='Project description'
          />
        </Form.Group>
        <Form.Group controlId='formGroupknowhow'>
          <Form.Label>Service</Form.Label>
          <Form.Control
            name='service'
            type='text'
            placeholder='Python backend API, Service... '
          />
        </Form.Group>
        <Form.Group controlId='formGroupImage'>
          <Form.Label>URL</Form.Label>
          <Form.Control name='url' type='text' placeholder='Project URL' />
        </Form.Group>
        <Form.Group controlId='formGroupknowhow'>
          <Form.Label>image URL</Form.Label>
          <Form.Control
            name='image'
            type='text'
            placeholder='Project image URL'
          />
          {/* <Form.File
            name='image'
            accept='image/png, image/jpeg, image/jpg'
            multiple='true'
            files
          /> */}
        </Form.Group>
        <Form.Group controlId='formGroupImageDescription'>
          <Form.Label>Image Description</Form.Label>
          <Form.Control
            name='imgdescription'
            type='text'
            placeholder='Image description'
          />
        </Form.Group>
        <Form.Group controlId='formGroupCompletion'>
          <Form.Label>Completion date</Form.Label>
          <Form.Control
            name='completion'
            type='date'
            pattern='/(^(((0[1-9]|1[0-9]|2[0-8])[-](0[1-9]|1[012]))|((29|30|31)[-](0[13578]|1[02]))|((29|30)[-](0[4,6,9]|11)))[-](19|[2-9][0-9])\d\d$)|(^29[-]02[-](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/'
            placeholder='Please pick a date'
          />
        </Form.Group>
        <Button type='submit'>Add</Button>
      </Form>
    </Jumbotron>
  );
};

export default ProjectForm;
