import {
  GET_PROJECTS,
  FILTER_PROJECTS,
  ADD_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
} from "../actions/projects";

const initalState = {
  projects: null,
  filteredProjects: null,
};

const projectsReducer = (state = initalState, action) => {
  let updatedProjects;
  console.log(action);
  switch (action.type) {
    case GET_PROJECTS:
      updatedProjects = action.projects.sort((a, b) => {
        return 0.5 - Math.random();
      });
      return {
        ...state,
        projects: updatedProjects,
        filteredProjects: updatedProjects,
      };
    case FILTER_PROJECTS:
      return {
        ...state,
        filteredProjects: action.filteredProjects,
      };
    case ADD_PROJECT:
      updatedProjects = state.projects.concat(action.projects.projects[0]);
      return {
        ...state,
        projects: updatedProjects,
        filteredProjects: updatedProjects,
      };
    case DELETE_PROJECT:
      updatedProjects = state.projects.filter(
        (project) => project._id !== action.id.project_id
      );
      return {
        ...state,
        projects: updatedProjects,
        filteredProjects: updatedProjects,
      };
    case UPDATE_PROJECT:
      updatedProjects = state.projects
        .filter((p) => p._id !== action.project.projects[0]._id)
        .concat(action.project.projects[0]);
      return {
        ...state,
        projects: updatedProjects,
        filteredProjects: updatedProjects,
      };
    default:
      return state;
  }
};

export default projectsReducer;
