import React from 'react';
import { Form, Button, Jumbotron } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as skillsActions from '../../store/actions/skills';
import { useAuth0 } from '../../react-auth0-spa';
import serializeForm from 'form-serialize';
import './EditSkillForm.css';

const SkillsForm = (props) => {
  const { getTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const skill = props.location.state.skill;
  const submitHandler = (e) => {
    e.preventDefault();
    const values = serializeForm(e.target, { hash: true });
    values._id = skill._id;
    getTokenSilently().then((token) =>
      dispatch(skillsActions.updateSkill(token, values))
    );
    props.onEditSkill();
  };

  const onClickHandler = () => {
    getTokenSilently().then((token) =>
      dispatch(skillsActions.deleteSkill(token, skill._id))
    );

    props.ondeleteSkill();
  };

  return (
    <Jumbotron className='edit-skill'>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='formGroupName'>
          <Form.Label>Skill Name</Form.Label>
          <Form.Control name='name' type='text' defaultValue={skill.name} />
        </Form.Group>
        <Form.Group controlId='formGroupImage'>
          <Form.Label>Category</Form.Label>
          <Form.Control name='category' as='select' defaultValue={skill.category}>
            <option>Development</option>
            <option>Productivity</option>
            <option>Design</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId='formGroupknowhow'>
          <Form.Label>KnowHow</Form.Label>
          <Form.Control
            name='knowhow'
            type='number'
            defaultValue={skill.knowhow}
          />
        </Form.Group>
        <Button
          className='skill-submit'
          pattern='/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/'
          title='please use the following format DD-MM-YYYY'
          type='submit'
        >
          Update
        </Button>
      </Form>
      <Button
        className='edit-skill-delete'
        variant='danger'
        onClick={() => onClickHandler()}
      >
        Delete
      </Button>
    </Jumbotron>
  );
};

export default withRouter(SkillsForm);
