import React from 'react';
import './SideMenu.css';

const Search = (props) => {



    return (
        <aside>
            <ul className="side-menu" >
                <a href="\">
                    <li className="menu-portfolio menu-selected" >Portfolio</li>
                </a>
                <a href="\skills">
                    <li className="menu-skills">Skills</li>
                </a>
                <a href="\myprofile">
                    <li className="menu-profile">Profile</li>
                </a>
            </ul>
        </aside>
    )
}

export default Search;