/*eslint no-unreachable: "off"*/

export const GET_SKILLS = "GET_SKILLS";
export const UPDATE_SKILL = "UPDATE_SKILL";
export const ADD_SKILL = "ADD_SKILL";
export const DELETE_SKILL = "DELETE_SKILL";

export const getSkills = () => {
  try {
    return async (dispatch) => {
      // Fetch to get all projects from server; Once the projects are fetched the array is randomized to make the experience of visiting the website unique every time.
      const response = await fetch(`https://nodejs-portfolio.herokuapp.com/skills`);
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const resData = await response.json();
      dispatch({
        type: GET_SKILLS,
        skills: resData.skills,
      });
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addSkill = (token, skill) => {
  try {
    return async (dispatch) => {
      const response = await fetch(
        `https://nodejs-portfolio.herokuapp.com/skills/add`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "Application/json",
          },
          body: JSON.stringify(skill),
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const resData = await response.json();
      dispatch({
        type: ADD_SKILL,
        skills: resData,
      });
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const deleteSkill = (token, skillId) => {
  try {
    return (dispatch) => {
      fetch(`https://nodejs-portfolio.herokuapp.com/skills/${skillId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((resData) =>
          dispatch({
            type: DELETE_SKILL,
            id: resData,
          })
        );
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const updateSkill = (token, skill) => {
  try {
    return (dispatch) => {
      fetch(`https://nodejs-portfolio.herokuapp.com/skills/${skill._id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "Application/json",
        },
        body: JSON.stringify(skill),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Something went wrong");
          }
          return response.json();
        })
        .then((resData) => {
          dispatch({
            type: UPDATE_SKILL,
            skill: resData,
          });
        });
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};
