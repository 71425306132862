import React,{Fragment} from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import SkillsForm from './components/Skills/SkillsForm';
import ProjectForm from './components/Project/ProjectForm';
import EditProjectForm from './components/Project/EditProjectForm';
import EditSkillForm from './components/Skills/EditSkillForm';
import NavBar from './components/NavBar';
import { useAuth0 } from './react-auth0-spa';
import Profile from './components/Profile';
import history from './utils/history';
import PrivateRoute from './components/PrivateRoute';
import Project from './components/Project/Projects';
import MyProfile from './components/Profile/MyProfile';
import SideMenu from './components/SideMenu';
import { Image, Spinner } from 'react-bootstrap';
import Skills from './components/Skills/Skills';
import './App.css';

function App(props) {
  const { loading } = useAuth0();

  // const [fetchingState, setFetchingState] = useState({
  //   fetching: false,
  // });

  if (loading) {
    return <Spinner animation='border' variant='primary' />;
  }

  return (
    <Fragment>
      <Router history={history}>
        <header>
          <NavBar />
          <SideMenu />
          <Image
            className='signature'
            src='https://firebasestorage.googleapis.com/v0/b/portfolio-44c4d.appspot.com/o/images%2Ffirma.png?alt=media&token=3c238d3b-ba07-4223-b73c-5d85536d3ca7'
            fluid
            alt="Jordi Melendez signature"
          />
        </header>

        <Switch>
          <Route path='/' exact component={Project} />

          <Route path='/skills' component={Skills} />

          <Route path='/myprofile' component={MyProfile} />

          <PrivateRoute path='/profile' component={Profile} />

          <PrivateRoute
            path='/skill-form'
            render={({ history }) => (
              <SkillsForm onCreateSkill={() => history.push('/skills')} />
            )}
          />

          <PrivateRoute
            path='/project-form'
            render={({ history }) => (
              <ProjectForm onCreateProject={() => history.push('/')} />
            )}
          />

          <PrivateRoute
            path='/project-edit'
            render={({ history }) => (
              <EditProjectForm onEditProject={() => history.push('/')} />
            )}
          />

          <PrivateRoute
            path='/skill-edit'
            render={({ history }) => (
              <EditSkillForm
                ondeleteSkill={id => history.push('/skills')}
                onEditSkill={project => history.push('/skills')}
              />
            )}
          />
          {/* <Route component={NotFound}/> */}
          <Route render={() => <h1 className="not_found">Sorry page Not found :( </h1>} />
        </Switch>
      </Router>
    </Fragment>
  );
}

export default App;
